export default grapesjs.plugins.add('cc-storage-manager', (editor, options) => {

	editor.StorageManager.add('cc-storage-manager', {
                
		/**
	     * Load the data
	     * @param  {Array} keys Array containing values to load, eg, ['gjs-components', 'gjs-style', ..]
	     * @param  {Function} clb Callback function to call when the load is ended
	     * @param  {Function} clbErr Callback function to call in case of errors
	     */
		load(keys, clb, clbErr) {
			const result = {};
			
			keys.forEach(key => {
				const value = CCRZ.ccCustomContentData.temporaryEditorConfig.attachment[key];
				if (value) {
					result[key] = value;
				}
			});
		
		// Might be called inside some async method
		clb(result);
	},
		
		/**
	   	 * Store the data
	     * @param  {Object} data Data object to store
	     * @param  {Function} clb Callback function to call when the load is ended
	     * @param  {Function} clbErr Callback function to call in case of errors
	     */
		store(data, clb, clbErr) {
			for (let key in data) {
			
				if(key == 'gjs-css'){
					data[key] = CCRZ.ccCustomContentFunction.cleanCSS(data[key]);
				}else if (key =='gjs-html'){
					data[key] = CCRZ.ccCustomContentFunction.cleanHTML(data[key]);
				}
				CCRZ.ccCustomContentData.temporaryEditorConfig[key] = data[key];
			}
			CCRZ.ccCustomContentFunction.updateAttachment(data);
			// Might be called inside some async method
			clb();
		}
	});
});